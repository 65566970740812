import React from 'react';
import ChallengeItem from './ChallengeItem';
import './ChallengeList.css';

function ChallengeList({ challenges }) {
  return (
    <section id="challenge-list">
      <ul>
        {challenges.map((challenge, index) => (
          <ChallengeItem key={index} challenge={challenge} />
        ))}
      </ul>
    </section>
  );
}

export default ChallengeList;
