import React, { useState } from 'react';
import './ChallengeForm.css';

function ChallengeForm({ onAddChallenge, onClose }) {
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [username, setUsername] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title && shortDescription && description && link && username) {
      onAddChallenge(title, shortDescription, description, link, username);
      onClose();  // Close the modal after adding the challenge
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Challenge Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Short Description"
        value={shortDescription}
        onChange={(e) => setShortDescription(e.target.value)}
        required
      />
      <textarea
        placeholder="Full Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Link"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
      <div className="button-container">
        <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
        <button type="submit" className="post-button">Post Challenge</button>
      </div>
    </form>
  );
}

export default ChallengeForm;
