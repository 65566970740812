import React, { useState } from 'react';
import './ChallengeItem.css';

function ChallengeItem({ challenge }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <li className="challenge-item">
      <h3>{challenge.title}</h3>
      <p className="short-description">{challenge.short_description}</p>
      <button onClick={toggleExpanded} className="expand-button">
        {isExpanded ? 'Hide Details' : 'Show Details'}
      </button>
      <div className={`details ${isExpanded ? 'expanded' : ''}`}>
        <p className="full-description"><strong>Full Description:</strong> {challenge.description}</p>
        <div className="footer">
          <p className="username"><strong>Posted by:</strong> {challenge.username}</p>
          {challenge.link && (
            <p className="link">
              <strong>Link:</strong> <a href={challenge.link} target="_blank" rel="noopener noreferrer">{challenge.link}</a>
            </p>
          )}
        </div>
      </div>
    </li>
  );
}

export default ChallengeItem;
