const API_URL = 'https://challenge-board-service-6561bf16b920.herokuapp.com/api/challenges';

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'An error occurred');
  }
  return response.json();
};

export const fetchChallenges = async () => {
  try {
    const response = await fetch(API_URL);
    return await handleResponse(response);
  } catch (error) {
    console.error('Error fetching challenges:', error);
    throw error;
  }
};

export const addChallenge = async (title, shortDescription, description, link, username) => {
  const newChallenge = {
    title,
    short_description: shortDescription,
    description,
    link,
    username
  };
  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newChallenge),
    });

    return await handleResponse(response);
  } catch (error) {
    console.error('Error adding challenge:', error);
    throw error;
  }
};