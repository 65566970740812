import React from 'react';
import './Modal.css';

function Modal({ children, onClose }) {
  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-btn" onClick={onClose}>&times;</span>
        <h2 className="modal-title">New Challenge</h2>
        {children}
      </div>
    </div>
  );
}

export default Modal;
