import React, { useState, useEffect, useCallback, useRef } from 'react';
import ChallengeList from './components/ChallengeList';
import ChallengeForm from './components/ChallengeForm';
import Footer from './components/Footer';
import Modal from './components/Modal';
import AuthenticationButton from './components/AuthenticationButton';
import { fetchChallenges, addChallenge as addChallengeToAPI } from './backend/ApiService';
import { useAuth0 } from "@auth0/auth0-react";
import './App.css';
import './components/Body.css';
import './components/Button.css';
import './components/Header.css';
import './components/HeaderActions.css';
import throttle from 'lodash/throttle';

const useModalAndAuth = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [isModalOpen, setModalOpen] = useState(false);
  const [shouldOpenModalAfterLogin, setShouldOpenModalAfterLogin] = useState(false);

  const openModal = () => {
    if (isAuthenticated) {
      setModalOpen(true);
    } else {
      setShouldOpenModalAfterLogin(true);
      loginWithRedirect();
    }
  };

  useEffect(() => {
    if (isAuthenticated && shouldOpenModalAfterLogin) {
      setModalOpen(true);
      setShouldOpenModalAfterLogin(false);
    }
  }, [isAuthenticated, shouldOpenModalAfterLogin]);

  return { isModalOpen, openModal, setModalOpen };
};

function App() {
  const { isModalOpen, openModal, setModalOpen } = useModalAndAuth();
  const [challenges, setChallenges] = useState([]);
  const [buttonOffset, setButtonOffset] = useState(20);
  const [isActionBarFixed, setActionBarFixed] = useState(false);
  const [headerActionsHeight, setHeaderActionsHeight] = useState(0);

  const headerRef = useRef(null);
  const buttonRef = useRef(null);
  const footerRef = useRef(null);
  const headerActionsRef = useRef(null);

  // Fetch challenges from backend when the component mounts
  const fetchChallengesData = useCallback(async () => {
    try {
      const data = await fetchChallenges();
      setChallenges(data);
    } catch (error) {
      console.error('Error fetching challenges:', error);
    }
  }, []);

  useEffect(() => {
    fetchChallengesData();
  }, [fetchChallengesData]);

  const addChallenge = async (title, shortDescription, description, link, username) => {
    try {
      const savedChallenge = await addChallengeToAPI(title, shortDescription, description, link, username);
      setChallenges((prevChallenges) => [...prevChallenges, savedChallenge]);
    } catch (error) {
      console.error('Error adding challenge:', error);
    }
  };

  const toggleModal = () => setModalOpen((prevState) => !prevState);

  useEffect(() => {
    if (headerActionsRef.current) {
      setHeaderActionsHeight(headerActionsRef.current.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const header = headerRef.current;
      const footer = footerRef.current;
      const button = buttonRef.current;

      if (header) {
        setActionBarFixed(header.getBoundingClientRect().bottom <= 0);
      }

      if (footer && button) {
        const footerRect = footer.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const offset = Math.max(20, viewportHeight - footerRect.top + 20);
        setButtonOffset(offset);
      }
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <header className="header" ref={headerRef}>
        <h1>Open Challenges</h1>
      </header>
      {isActionBarFixed && <div style={{ height: headerActionsHeight }} />} {/* Placeholder to maintain space */}
      <div ref={headerActionsRef} className={`header-actions ${isActionBarFixed ? 'fixed' : ''}`}>
        <button className="header-action-button">Filter</button>
        <button className="header-action-button">Search</button>
        <AuthenticationButton />
      </div>
      <button
        ref={buttonRef}
        onClick={openModal}
        className="new-challenge-button"
        style={{ bottom: `${buttonOffset}px`, transition: 'bottom 0.3s ease' }}
      >
        <i className="fas fa-plus"></i> Post a New Challenge
      </button>
      <main>
        <ChallengeList challenges={challenges} />
        {isModalOpen && (
          <Modal onClose={toggleModal}>
            <ChallengeForm onAddChallenge={addChallenge} onClose={toggleModal} />
          </Modal>
        )}
      </main>
      <Footer ref={footerRef} />
    </div>
  );
}

export default App;
