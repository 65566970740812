import React from 'react';
import './Footer.css';

const Footer = React.forwardRef((props, ref) => {
  return (
    <footer ref={ref} className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} Open Challenges. All Rights Reserved.</p>
        <div className="footer-links">
          <a href="#about">About</a>
          <a href="#contact">Contact</a>
          <a href="#privacy-policy">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
